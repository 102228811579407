const blogPostMeta = `
id
title
postDate
metaTitle
metaDescription
metaKeywords
typeHandle
summary
blogFeaturedImage {
  id
  url
}
author {
  firstName
  lastName
}
postCategories {
  id
  title
  slug
}
postTags {
  id
  title
  slug
}
`

export default blogPostMeta;
