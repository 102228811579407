import { setupMultistoreRoutes } from '@vue-storefront/core/lib/multistore';
import App from './App.vue';
import routes from './router';
import Vue from 'vue';
import VueProgressBar from 'vue-progressbar';
import '@vue-storefront/core/lib/passive-listeners';
import { once } from '@vue-storefront/core/helpers';
import { module as cartModule } from './store/cart';

import { claimsStore } from 'theme/store/claims';
import { uiStore } from 'theme/store/ui';
import { StorageManager } from '@vue-storefront/core/lib/storage-manager';
import { builderUiStore } from 'theme/store/bikebuilder-ui';
import { craftCmsStore } from 'theme/store/craft-cms';
import { helpStore } from 'theme/store/craft/help';
import { oculizmStore } from 'theme/store/oculizm';
import { brexitStore } from 'theme/store/brexit';
import { graphqlProductStore } from 'theme/store/graphql-product';
import { graphqlCartStore } from 'theme/store/graphql-cart';
import { graphqlAccountStore } from 'theme/store/graphql-account';
import { eswStore } from 'theme/store/esw';
import { trendingStore } from 'theme/store/trending';

import { bikeStore } from 'theme/store/configurator/bike';
import { shareStore } from 'theme/store/configurator/share';
import { appStore } from 'theme/store/configurator/app';
import { colorStore } from 'theme/store/configurator/color';
import { configuratorStore } from 'theme/store/configurator/configurator';
import { customColourStore } from 'theme/store/custom-colour';
import { preSpecStore } from 'theme/store/pre-spec';
import { craftBikeStore } from 'theme/store/craft-bike';
import { storeIpStore } from 'theme/store/store-ip';
import { newCheckoutStore } from 'theme/store/new-checkout';

// Import Global Styles
// import './css/tailwind.scss';
import './css/main.scss';
import './css/ruif/css/styles.less';
import VTooltip from 'v-tooltip';

once('__VUE_EXTEND_DROPPOINT_VPB__', () => {
  Vue.use(VueProgressBar);
});
Vue.use(VTooltip, {
  defaultHtml: true,
  defaultPlacement: 'bottom-end'
});
const themeEntry = App;
function initTheme (app, router, store, config, ssrContext) {
  store.registerModule('themeCart', cartModule);
  // Register theme routes for the current store. In a single store setup this will add routes exactly as they are in the router definition file '[theme]/router/index.js'
  // In a multistore setup, it depends on the config setting 'appendStoreCode' for the current store
  // - true = the store code will be added to the front of all routes, e.g. name: 'de-checkout', path: '/de/checkout'
  // - false = the store code will not be added. In this case you need to define custom routes for each of your stores
  // You can also define custom routes to use a different component, for example for German storeView checkout
  // To do so, exclude the desired storeView from the config.storeViews.mapStoreUrlsFor, set appendStoreCode = false, and map all the urls by your own like:
  // { name: 'de-checkout', path: '/checkout', component: CheckoutCustomized }
  // The 4th parameter is the route priority - a higher number will ensure the theme routes override any module routes. The default is 0.
  setupMultistoreRoutes(config, router, routes, 10);

  StorageManager.init('claims');
  store.registerModule('storeIp', storeIpStore);
  store.registerModule('claims', claimsStore);
  store.registerModule('ui', uiStore);
  store.registerModule('builderUi', builderUiStore);
  store.registerModule('craftCms', craftCmsStore);
  store.registerModule('help', helpStore);
  store.registerModule('oculizm', oculizmStore);
  store.registerModule('brexit', brexitStore);
  store.registerModule('graphqlProduct', graphqlProductStore);
  store.registerModule('graphqlCart', graphqlCartStore);
  store.registerModule('graphqlAccount', graphqlAccountStore);
  store.registerModule('esw', eswStore);
  store.registerModule('trending', trendingStore);
  store.registerModule('bike', bikeStore);
  store.registerModule('share', shareStore);
  store.registerModule('app', appStore);
  store.registerModule('color', colorStore);
  store.registerModule('configurator', configuratorStore);
  store.registerModule('customColour', customColourStore);
  store.registerModule('builderPreSpec', preSpecStore);
  store.registerModule('craftBike', craftBikeStore);
  store.registerModule('newCheckout', newCheckoutStore);
}

export { themeEntry, initTheme };
